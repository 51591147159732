import { useState } from 'react';
import cn from 'classnames';
import s from './find-stores-in-states.module.scss';
import { MenuItem, FormControl } from '@mui/material';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { SliceWrapper, ImageWrapper } from '@ee-monorepo/prismic/shared/ui';
import { richTextSerializerHeading1Override } from '@ee-monorepo/prismic/shared/serializers';
import { useStates, State } from '@ee-monorepo/shared/data-access/services';
import * as PrismicFields from '@ee-monorepo/prismic/shared/types';
import styles from './find-stores-in-states.module.scss';
import { Button } from '@ee-monorepo/shared/ui/button';
import { Select } from '@ee-monorepo/shared/ui/select';
import { PrismicRichText } from '@prismicio/react';
export interface FindStoresInStatesProps extends PrismicFields.SliceBase {
  slice: {
    primary: {
      header_title: PrismicFields.RichTextField;
      map_image: PrismicFields.Image & {
        mobile: PrismicFields.Image;
      };
    };
  };
}

export function FindStoresInStates({
  index,
  slice: { primary },
}: FindStoresInStatesProps) {
  const { header_title, map_image } = primary;
  const translate = useTranslations();
  const { states } = useStates();
  const [state, setState] = useState('');

  const renderState = (state: State) => {
    return (
      <Button
        key={state.id}
        href={`/states/${state.abbreviation.toLowerCase()}`}
        variant="link"
        className={cn('row', 'p-0 mb-3')}
        data-testid="state"
      >
        {state.name}
      </Button>
    );
  };

  return (
    <SliceWrapper data-testid="find-stores-wrapper" data-slice-index={index}>
      <div
        className={cn(
          'mb-6',
          'md:mb-10',
          'snap-container',
          'md:text-center',
          'text-SnapDarkBlue'
        )}
      >
        <PrismicRichText
          field={header_title}
          components={richTextSerializerHeading1Override}
        />
      </div>
      <ImageWrapper
        className={'hidden md:block w-full h-auto'}
        src={map_image?.url}
        alt={map_image?.alt}
        width={map_image?.dimensions?.width}
        height={map_image?.dimensions?.height}
      />
      <ImageWrapper
        className={'block md:hidden w-full h-auto'}
        src={map_image?.mobile?.url}
        alt={map_image?.mobile?.alt}
        width={map_image?.mobile?.dimensions?.width}
        height={map_image?.mobile?.dimensions?.height}
      />
      <FormControl
        variant="outlined"
        className={`${styles.states_dropdown} block md:hidden mx-4`}
      >
        <Select
          displayEmpty
          value={state}
          renderValue={(selected: string) => {
            if (selected === '') {
              return translate('shared.mobile_state_selector_placeholder');
            }

            return selected;
          }}
          noLabel
        >
          {states.map((state) => {
            return (
              <Link
                href={`/states/${state.abbreviation.toLowerCase()}`}
                key={state.id}
                onClick={() => {
                  setState(state.name);
                }}
                className={styles.menuItemAnchor}
              >
                <MenuItem className={styles.menuItem}>{state.name}</MenuItem>
              </Link>
            );
          })}
        </Select>
      </FormControl>
      <div className="snap-container hidden md:block">
        <div
          className={cn('snap-row', 'secondaryHeavyFont', styles.states)}
          data-testid="states-container"
        >
          <div className="w-1/6">
            {states.slice(0, 9).map((state) => renderState(state))}
          </div>
          <div className="w-1/6">
            {states.slice(9, 18).map((state) => renderState(state))}
          </div>
          <div className="w-1/6">
            {states.slice(18, 27).map((state) => renderState(state))}
          </div>
          <div className="w-1/6">
            {states.slice(27, 36).map((state) => renderState(state))}
          </div>
          <div className="w-1/6">
            {states.slice(36, 45).map((state) => renderState(state))}
          </div>
          <div className="w-1/6">
            {states.slice(45, 50).map((state) => renderState(state))}
          </div>
        </div>
      </div>
    </SliceWrapper>
  );
}
