import { InputAdornment, styled } from '@mui/material';
import { Select as MuiSelect } from '@mui/material';
import { IconChevronDown } from '@ee-monorepo/shared/ui/icons';
import ErrorIcon from '@mui/icons-material/Error';
import cn from 'classnames';
import { forwardRef } from 'react';
import s from './shared-ui-select.module.scss';

export const CustomSelect = styled(MuiSelect as any)(({ theme }) => ({
  paddingLeft: 16,
}));

export const Select = forwardRef(
  (
    {
      noLabel,
      menuStyles,
      MenuProps,
      children,
      ...props
    }: any & { noLabel?: boolean; menuStyles?: string },
    ref
  ) => {
    return (
      <CustomSelect
        {...props}
        ref={ref}
        className={cn(s.select, 'w-full', props.className)}
        IconComponent={IconChevronDown}
        inputProps={{
          ...props?.inputProps,
          className: cn(props?.inputProps?.className, {
            [s.noLabel]: noLabel,
          }),
        }}
        MenuProps={{
          ...MenuProps,
          style: {
            ...menuStyles,
          },
        }}
        endAdornment={
          props.error ? (
            <InputAdornment className={cn('mr-10', 'pr-3')} position="end">
              <ErrorIcon className="error-icon" />
            </InputAdornment>
          ) : (
            props?.endAdornment
          )
        }
      >
        {children}
      </CustomSelect>
    );
  }
);
